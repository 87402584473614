/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { env } from '../env'
import { getUTMParams } from '../helpers/getUTMParams'

window.PRODUCT_TRACKING_KEY = 'analyze'
window.CLIENT_NAME = 'analyzeWeb'
;(function () {
  const analytics = (window.analytics = window.analytics || [])
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error('Segment snippet included twice.')
    else {
      analytics.invoked = !0
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ]
      analytics.factory = function (e) {
        return function () {
          // eslint-disable-next-line prefer-rest-params
          const t = Array.prototype.slice.call(arguments)
          t.unshift(e)
          analytics.push(t)
          return analytics
        }
      }
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e]
        analytics[key] = analytics.factory(key)
      }
      analytics.load = function (src, options, onError) {
        const t = document.createElement('script')
        t.type = 'text/javascript'
        t.async = !0
        t.onerror = onError
        t.src = src
        const n = document.getElementsByTagName('script')[0]
        n.parentNode.insertBefore(t, n)
        analytics._loadOptions = options
      }
      analytics._writeKey = env.VITE_SEGMENT_KEY
      analytics._cdn = 'https://segment-analytics.buffer.com'
      analytics.SNIPPET_VERSION = '4.15.2'
      analytics.load(
        `https://segment-analytics.buffer.com/analytics.js/v1/${env.VITE_SEGMENT_KEY}/analytics.min.js`,
        {
          obfuscate: true,
          integrations: {
            'Segment.io': { apiHost: 'segment-api.buffer.com/v1' },
          },
        },
        function onError() {
          throw new Error('SegmentLibrary::NotLoaded')
        },
      )
      const urlParams = new URLSearchParams(window.location.search)
      analytics.page({
        cta: urlParams.get('cta'),
        ...getUTMParams(urlParams),
        screenWidth: screen.width,
        screenHeight: screen.height,
        viewportWidth: window.innerWidth,
        viewportHeight: window.innerHeight,
      })

      // If something breaks loading Segment let's try with the proxy
      window.onerror = (msg, source, line, col, err) => {
        if (msg === 'Uncaught Error: SegmentLibrary::NotLoaded') {
          analytics._cdn = 'https://breadcrumbs.buffer.com'
          analytics.load(
            `https://breadcrumbs.buffer.com/?key=${env.VITE_SEGMENT_KEY}`,
            {
              obfuscate: true,
              integrations: {
                'Segment.io': { apiHost: 'breadcrumbs.buffer.com/v1' },
              },
            },
            (onError = () => {
              data = { userAgent: navigator.userAgent }
              fetch('https://buffer-segment-monitor.bufferapp.workers.dev/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data),
              })
              throw new Error('SegmentProxy::NotLoaded')
            }),
          )
        }
      }
    }
})()
