import React from 'react'
import styled from 'styled-components'
import NavSidebar from '~/nav-sidebar'
import { Loader } from '@buffer-mono/legacy-bufferapp-components'
import {
  LayoutPage,
  LayoutSidebar,
  LayoutLeft,
  LayoutRight,
  LayoutContents,
} from '~/shared-components'

const Centralize = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-items: center;
  align-items: center;

  svg {
    width: 32px !important;
    height: 32px !important;
  }
`

// @ts-expect-error TS(7031) FIXME: Binding element 'path' implicitly has an 'any' typ... Remove this comment to see the full error message
export default ({ path }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <LayoutPage full>
    <LayoutSidebar>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ route: any; }' is not assignable to type '... Remove this comment to see the full error message */}
      <NavSidebar route={path} />
    </LayoutSidebar>
    <LayoutLeft />
    <LayoutContents>
      <Centralize>
        <Loader />
      </Centralize>
    </LayoutContents>
    <LayoutRight />
  </LayoutPage>
)
