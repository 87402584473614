import React from 'react'
import { Route, Switch } from 'react-router'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@loa... Remove this comment to see the full error message
import loadable from '@loadable/component'
import CrossSell from '~/campaigns'
import LoadingPage from '../LoadingPage'
import ReportsList from '../ReportsList'
import SingleProfilePage from '../SingleProfilePage'

const AggregatesPage = loadable(() => import('../AggregatesPage'), {
  fallback: <LoadingPage path="/aggregates" />,
})
const ReportsPage = loadable(() => import('../ReportsPage'), {
  fallback: <LoadingPage path="/reports" />,
})

const DefaultPage = loadable(() => import('../DefaultPage'), {
  fallback: <LoadingPage path="/" />,
})

const ConnectionPage = loadable(() => import('../ConnectionPage'), {
  fallback: <LoadingPage path="/" />,
})

const Routes = () => (
  <Switch>
    <Route path="/reports/:id" component={ReportsPage} />
    <Route path="/reports" component={ReportsList} />
    <Route path={['/campaigns', '/tags']} component={CrossSell} />
    <Route path="/aggregates" component={AggregatesPage} />
    <Route path="/connect/:channel" component={ConnectionPage} />
    <Route path="/:channel/:tabId/:id?" component={SingleProfilePage} />
    <Route path="/" component={DefaultPage} />
  </Switch>
)

export default Routes
