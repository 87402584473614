import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Loader } from '@buffer-mono/legacy-bufferapp-components'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@loa... Remove this comment to see the full error message
import loadable from '@loadable/component'
import styled from 'styled-components'

import { CanAccess } from '~/account'
import { actions as profilesActions } from '~/profile-selector'
import { grayLighter } from '@bufferapp/ui/style/colors'

import { HelpScoutBeacon } from '../../../helpScoutBeacon'
import Banner from '../Banner'
import Notifications from '../Notifications'
import Routes from '../Routes'

const Container = styled.div`
  height: 100%;
  width: 100%;
  background: ${grayLighter};
  display: flex;
  flex-direction: column;
`

const BannerHolder = styled.div`
  flex: 0;
`

const ContentHolder = styled.div`
  flex: 1;
  overflow-y: auto;
`

const ReportExport = loadable(() => import('../ReportExport'), {
  fallback: <Loader />,
})

// @ts-expect-error TS(7031) FIXME: Binding element 'pathname' implicitly has an 'any'... Remove this comment to see the full error message
function isSocialProfilePage({ pathname }) {
  return pathname.match(/instagram|facebook|twitter/)
}

export class App extends React.Component {
  componentDidMount() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
      location: { pathname },
    } = this.props
    HelpScoutBeacon.updateHelpScoutBeacon(pathname)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
      location: { pathname },
      // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      user,
      // @ts-expect-error TS(2339) FIXME: Property 'account' does not exist on type 'Readonl... Remove this comment to see the full error message
      account,
    } = this.props
    const previousLocation = prevProps.location.pathname

    if (pathname !== previousLocation) {
      HelpScoutBeacon.updateHelpScoutBeacon(pathname)
    }
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'profilesWithFreshData' does not exist on... Remove this comment to see the full error message
      profilesWithFreshData,
      // @ts-expect-error TS(2339) FIXME: Property 'refreshProfiles' does not exist on type ... Remove this comment to see the full error message
      refreshProfiles,
      // @ts-expect-error TS(2339) FIXME: Property 'closeFreshDataAlert' does not exist on t... Remove this comment to see the full error message
      closeFreshDataAlert,
      // @ts-expect-error TS(2339) FIXME: Property 'hasInvalidProfiles' does not exist on ty... Remove this comment to see the full error message
      hasInvalidProfiles,
      // @ts-expect-error TS(2339) FIXME: Property 'changeOrganization' does not exist on ty... Remove this comment to see the full error message
      changeOrganization,
      // @ts-expect-error TS(2339) FIXME: Property 'accountChannels' does not exist on type ... Remove this comment to see the full error message
      accountChannels,
    } = this.props

    return (
      <Container>
        <CanAccess>
          <BannerHolder>
            <Banner
              // @ts-expect-error TS(2322) FIXME: Type '{ location: Location; hasInvalidProfiles: an... Remove this comment to see the full error message
              location={location}
              hasInvalidProfiles={hasInvalidProfiles}
              isSocialProfilePage={isSocialProfilePage}
            />
            <Notifications
              profilesWithFreshData={profilesWithFreshData}
              refreshProfiles={refreshProfiles}
              closeFreshDataAlert={closeFreshDataAlert}
            />
          </BannerHolder>
          <ContentHolder>
            <Routes />
          </ContentHolder>
        </CanAccess>
      </Container>
    )
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
App.propTypes = {
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    avatar: PropTypes.string,
  }).isRequired,
  account: PropTypes.shape({
    isOwner: PropTypes.bool,
  }).isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  profilesWithFreshData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      username: PropTypes.string,
    }),
  ).isRequired,
  closeFreshDataAlert: PropTypes.func.isRequired,
  refreshProfiles: PropTypes.func.isRequired,
  hasInvalidProfiles: PropTypes.bool.isRequired,
  changeOrganization: PropTypes.func.isRequired,
}

const AppContent = connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'appSidebar' does not exist on type 'Defa... Remove this comment to see the full error message
    user: state.appSidebar.user,
    // @ts-expect-error TS(2339) FIXME: Property 'account' does not exist on type 'Default... Remove this comment to see the full error message
    account: state.account,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    profilesWithFreshData: state.profiles.profilesWithFreshData,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    hasInvalidProfiles: state.profiles.hasInvalidProfiles,
    // @ts-expect-error TS(2339) FIXME: Property 'profiles' does not exist on type 'Defaul... Remove this comment to see the full error message
    accountChannels: state.profiles.accountChannels,
  }),
  (dispatch) => ({
    refreshProfiles: () => dispatch(profilesActions.refreshProfiles()),
    closeFreshDataAlert: () => dispatch(profilesActions.disregardFreshData()),
    // @ts-expect-error TS(7006) FIXME: Parameter 'orgId' implicitly has an 'any' type.
    changeOrganization: (orgId) =>
      dispatch({
        type: 'ORGANIZATION_CHANGED',
        organizationId: orgId,
      }),
  }),
  // @ts-expect-error TS(2345) FIXME: Argument of type 'typeof App' is not assignable to... Remove this comment to see the full error message
)(withRouter(App))

export default () => (
  <Switch>
    <Route path="/export/reports/:id" component={ReportExport} />
    <Route component={AppContent} />
  </Switch>
)
