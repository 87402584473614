import { actions } from '@buffer-mono/async-data-fetch'
import {
  actions as exportActions,
  actionTypes as exportActionTypes,
} from '~/png-export'
import {
  actionTypes as exportCSVActionTypes,
  actions as exportCSVActions,
} from '~/csv-export'
import { actionTypes } from './reducer'
import moment from 'moment-timezone'

// @ts-expect-error TS(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
const formatDataForCSVExport = ({ data, baseReach }) => {
  if (!data || !data.length) {
    return null
  }

  const exportData = {
    day: [],
    time: [],
    predictedReach: [],
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'm' implicitly has an 'any' type.
  data.forEach((m) => {
    // @ts-expect-error TS(7006) FIXME: Parameter 'i' implicitly has an 'any' type.
    m.data.forEach((i) => {
      // to calculate the percentage increase you subtract the value from the lowest weekly value
      const increase = i.prediction - baseReach
      // then you divide the increase by the value and multiply by 100 to get it as a percent
      const percentage = (increase / i.prediction) * 100
      // @ts-expect-error TS(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      exportData.day.push(m.weekday)
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      exportData.time.push(moment(i.hour, 'h').format('ha'))
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      exportData.predictedReach.push(`${percentage.toFixed(2)}% increase`)
    })
  })

  return exportData
}

// @ts-expect-error TS(7006) FIXME: Parameter 'store' implicitly has an 'any' type.
export default (store) => (next) => (action) => {
  // eslint-disable-line no-unused-vars
  const { dispatch, getState } = store
  const state = getState()
  switch (action.type) {
    case actionTypes.FETCH:
      dispatch(
        actions.fetch({
          name: 'best_time',
          args: {
            profileId: state.profiles.selectedProfileId,
            timezone: state.profiles.selectedProfile.timezone,
          },
        }),
      )
      break
    case exportCSVActionTypes.EXPORT_TO_CSV_START:
      dispatch(
        exportCSVActions.exportChart(
          'best-time-to-post',
          formatDataForCSVExport(getState().bestTime),
        ),
      )
      break
    case exportActionTypes.EXPORT_TO_PNG_START:
      dispatch(
        exportActions.exportChart(
          'js-dom-to-png-best-time-to-post',
          'best-time-to-post',
        ),
      )
      break
    default:
      break
  }
  return next(action)
}
