import { env } from '../env'

window.API_GATEWAY_URL = env.VITE_GRAPHQL_API
// used in async-data-fetch to pass as a header to track in DD custom metrics
window.xBufferClientId = env.VITE_X_BUFFER_CLIENT_ID
const appShellScript = document.createElement('script')
appShellScript.src = env.VITE_NAVIGATOR_URL
document.body.prepend(appShellScript)

// Copied over from old index.ejs file - hides the navigtor when exporting
const isExport = !!window.location.pathname.match(/^\/export/) || false
if (isExport) {
  ;(document.getElementById('root') as HTMLDivElement).style.height = 'auto'
  ;(document.getElementById('navigator') as HTMLDivElement).remove()
}
