/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
// @ts-nocheck
import { env } from '../env'

if (env.VITE_NODE_ENV === 'production') {
  ;(function (h, o, u, n, d): void {
    h = h[d] = h[d] || {
      q: [],
      onReady: function (c) {
        h.q.push(c)
      },
    }
    d = o.createElement(u)
    d.async = 1
    d.src = n
    n = o.getElementsByTagName(u)[0]
    n.parentNode.insertBefore(d, n)
  })(
    window,
    document,
    'script',
    'https://www.datadoghq-browser-agent.com/datadog-rum.js',
    'DD_RUM',
  )

  DD_RUM.onReady(function () {
    DD_RUM.init({
      clientToken: 'pubcb815396009349cd8e79a3e1f75bc0f5',
      applicationId: 'a1c7997f-80b9-45fb-ad82-e1f1496884b7',
      site: 'datadoghq.com',
      service: 'Analyze',
      version: '<%=  %>',
      sampleRate: 5,
      trackInteractions: true,
    })
  })
}
